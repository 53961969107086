<template>
    <div>
        <div v-if="loading">
            <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
                <ProgressSpinner></ProgressSpinner>
            </div>
        </div>
        <div v-if="!loading && error === null">
            <div class="grid">
                <div class="col-12 lg:col-6">
                    <Loan :loan="loan"></Loan>
                </div>
                <div class="col-12 lg:col-6">
                    <Borrower :borrower="borrower"></Borrower>
                </div>
                <div class="col-12">
                     <EventsTable
                        :title="`События с займом №${loan.id}`"
                        :default-data="{loanId: loan.id}"
                        :ignored-fields="['loanId', 'fio']"
                     ></EventsTable>
                </div>
                <div class="col-12">
                    <ReportsTable
                        :title="`Отчеты с займом №${loan.id}`"
                        :loan-id="loan.id"
                    ></ReportsTable>
                </div>
            </div>
        </div>
        <div v-if="!loading && error !== null">
            <Error :message="error"></Error>
        </div>
    </div>
</template>

<script>
import Borrower from "@/components/Event/Borrower.vue";
import Loan from "@/components/Event/Loan.vue";
import Error from "@/components/Error";
import EventsTable from "@/components/tables/EventsTable.vue";
import ReportsTable from "@/components/tables/ReportsTable.vue";
import LoanService from "@/service/LoanService";
import ResponseService from "@/service/ResponseService";

export default {
    name: "LoanView",
    components: { Borrower, Loan, Error, EventsTable, ReportsTable },
    data() {
        return {
            loading: true,
            error: null,
            loan: null,
            borrower: null
        }
    },
    methods: {
        getLoan() {
            this.loading = true;
            LoanService.getLoan(this.$router.currentRoute.value.params.id).then((data) => {
                this.loan = data.loan
                this.borrower = data.borrower
            })
            .catch((err) => {
                ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
                this.error = 'Произошла ошибка'
            })
            .finally(() => {
                this.loading = false
            })
        }
    },
    mounted() {
        this.getLoan()
    }
}
</script>

<style scoped>

</style>